import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { Flex, Box } from 'reflexbox'
import { Wrap, Title, Copy, ButtonLink } from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'

export default () => {
  return (
    <Wrap>
      <SEO title="Sign up" />
      <FocusHeader title="Sign up" backUrl="/" />
      <FocusBody>
        <Flex flexWrap={['wrap']} width={`100%`} mb={[1, 4]}>
          <Box width={[1, 1]}>
            <Title>Exclusive to Shillington Students and Graduates</Title>
            <Copy>
              <p>
                If you studied at Shillington you're welcome to keep on using
                The Guide.
              </p>
            </Copy>
            <ButtonLink>
              <Link to="/account-setup?id=shilumni">Setup Your Account</Link>
            </ButtonLink>
          </Box>
        </Flex>

        <Copy>
          <p>
            <br />
            Already setup? Sign in <Link to="/sign-in">here</Link>.
          </p>
        </Copy>
      </FocusBody>
    </Wrap>
  )
}
